<template lang="">
  <v-app id="inspire">
    <v-container fluid fill-height class="container-login">
      <v-row class="layout-login flex-column">
        <v-col class="col-img-login">
          <v-img
            contain
            class="img-login"
            lazy-src="../../assets/Gbs.svg"
            max-height="165"
            max-width="250"
            src="../../assets/Gbs.svg"
          ></v-img>
        </v-col>
        <v-col class="container-card">
          <CardResetPassword />
        </v-col>
      </v-row>
     
    </v-container>
  </v-app>
</template>
<script>
import { CardResetPassword } from "../../components/login";
import { FooterLogin } from "../../components/_partials";

export default {
  name: "ResetPassword",
  components: {
    CardResetPassword,
    FooterLogin
  }
};
</script>
